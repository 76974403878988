import {useEffect, useRef, useState} from "react";
import {useWindowSize} from "@uidotdev/usehooks";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import {imageToText} from "../../service/OCR";
import {CircularProgress, Fab} from "@mui/material";

import {useCamera} from "../../hooks/useCamera";
import ScanResultDialog from "./ScanResultDialog";

export default function Home() {

    const canvasRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const size = useWindowSize()

    const [cameraVisible, setCameraVisible] = useState(true)

    const [scanResult, setScanResult] = useState<string>('')

    const {captureImage, imageData, switchCameraFacingMode, refreshCamera} = useCamera();

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setCameraVisible(false);
            } else {
                setCameraVisible(true);
                setTimeout(() => {
                    refreshCamera()
                }, 200)
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    async function handleScan() {
        setLoading(true)
        const handleScanFunction = async () => {
            const newScanCanvas = await captureImage()
            if (!newScanCanvas) return;
            const text = await imageToText(newScanCanvas)

            if (!text) return;

            if (text) {
                setScanResult(text)
            }
        }
        await handleScanFunction()
        setLoading(false)
    }

    const handleScanResultSelect = async (result: string) => {
        if (!result) return;
        const url = `https://www.goedkoopprinten.nl/catalogsearch/result/?q=${result}`;
        window.open(url, "_blank");
        setScanResult('')
    }

    return <>
        <ScanResultDialog scanResult={scanResult} onClose={() => setScanResult('')}
                          onSelectScanResult={handleScanResultSelect}></ScanResultDialog>

        <span style={{
            position: 'fixed',
            left: 0,
            right: 0,
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
            color: 'white', zIndex: 2, top: '0', textAlign: 'center', padding: '8px'
        }}>Richt uw camera op het typenummer van uw printer en druk op de groene cameraknop.</span>
        {cameraVisible ?

            <div style={{
                position: "fixed",
                display: "flex",
                height: '100%',
                width: '100%',
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div className="VideoAndCanvas">
                    <video width={size.width ?? 100} style={{objectFit: 'contain'}}/>
                    <canvas style={{opacity: 0}}/>
                </div>
            </div> : null}
        <div style={{
            background: 'black',
            opacity: '0.5',
            height: '50%',
            width: '100vw',
            zIndex: 1,
            position: 'fixed',
            bottom: size.height ? (size.height / 2 + 35) : 0
        }}></div>
        <div style={{
            background: 'black',
            opacity: '0.5',
            height: '50%',
            width: '100vw',
            position: 'fixed',
            zIndex: 1,
            top: size.height ? (size.height / 2 + 35) : 0
        }}></div>
        <div id={'black-bar-left'} style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            margin: 'auto',
            width: '5%', // Adjust according to your needs
            height: '70px',
            backgroundColor: 'black',
            opacity: 0.5 // Adjust opacity to match existing bars
        }}></div>
        <div id={'scan-area'} ref={canvasRef} style={{
            position: 'fixed',
            zIndex: 2,
            height: '70px',
            width: size.width ? (size.width * 0.9) : 0,
            border: '2px solid rgb(115, 226, 97)',
            borderRadius: '8px',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto'
        }}
        >
            {loading ? <div style={{width: '100%', height: '100%'}}>
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: 'black',
                    opacity: 0.4,
                    zIndex: 1
                }}>
                </div>
                <CircularProgress color={'warning'} style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: 'auto'
                }}/>
            </div> : null}
        </div>
        <div id={'black-bar-right'} style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            margin: 'auto',
            width: '5%', // Adjust according to your needs
            height: '70px',
            backgroundColor: 'black',
            opacity: 0.5 // Adjust opacity to match existing bars
        }}></div>

        <Fab id={'camera-button'}
             style={{
                 background: loading ? 'grey' : '#73e261',
                 zIndex: 2,
                 position: 'fixed',
                 bottom: '16px',
                 right: '0',
                 left: '0',
                 margin: 'auto'
             }}
             onClick={handleScan} disabled={loading}><CameraAltIcon/></Fab>
    </>
}
import {Fab} from "@mui/material";
import CameraImplementation from "../camera/CameraImplementation";
import {useEffect, useLayoutEffect, useState} from "react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DownloadIcon from '@mui/icons-material/Download';

import HelpDialog from "../help/HelpDialog";
import InstallDialog from "../help/InstallDialog";

export default function Home() {
    const [helpVisible, setHelpVisible] = useState(true)
    const [installDialogVisible, setInstallDialogVisible] = useState(false)

    const showHelp = () => {
        setHelpVisible(true)
    }

    useEffect(() => {
        setHelpVisible(true)
    }, [])

    const helpCloseHandler = () => {
        setHelpVisible(false)
    }

    const installCloseHandler = () => {
        setInstallDialogVisible(false)
    }

    return <>
        <HelpDialog open={helpVisible} onClose={helpCloseHandler}/>
        <InstallDialog open={installDialogVisible} onClose={installCloseHandler}/>

        <CameraImplementation/>

        <Fab
            style={{
                background: '#aaaaaa',
                zIndex: 2,
                position: 'fixed',
                bottom: '16px',
                left: '16px',
                margin: 'auto'
            }}
            onClick={showHelp}><QuestionMarkIcon/></Fab>

        <Fab id={'camera-button'}
             style={{
                 background: '#aaaaaa',
                 zIndex: 2,
                 position: 'fixed',
                 bottom: '16px',
                 right: '16px',
                 margin: 'auto'
             }}
             onClick={() => setInstallDialogVisible(true)}><DownloadIcon/></Fab>
    </>

}
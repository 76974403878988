import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Zoom} from "@mui/material";
import React, {forwardRef, ReactElement, useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Chrome from "./help-pages/chrome";
import Samsung from "./help-pages/samsung";
import Opera from "./help-pages/opera";
import Safari from "./help-pages/safari";
import Other from "./help-pages/other";

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});

export default function InstallDialog({open, onClose}: Props) {

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [selected, setSelected] = useState<string>('initial')

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    const handleInstallClick = () => {
        console.log('test')
        if (deferredPrompt) {
            // @ts-ignore
            deferredPrompt.prompt();
            // @ts-ignore
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };


    const initialContent = <div>
        <p>Welke browser gebruikt u?</p>
        <Button className="browser" onClick={() => handleContentSwitch("chrome")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/chrome/chrome.png'} alt={'.'}/>
                <span>Chrome</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => handleContentSwitch("samsung")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/samsung/samsung.png'} alt={'.'}/>
                <span>Samsung internet</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => handleContentSwitch("opera")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/opera/opera.png'} alt={'.'}/>
                <span>Opera</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => handleContentSwitch("safari")}>
            <div className={"buttonContent"}>
                <img src={'/assets/images/safari/safari.png'} alt={'.'}/>
                <span>Safari</span>
            </div>
        </Button>
        <Button className="browser" onClick={() => handleContentSwitch("other")}>
            <div className={"buttonContent"}>
                <PsychologyAltIcon sx={{position: "absolute", left: '8px', width: '50px', height: '80px'}}/>
                <span>Ik weet het niet</span>
            </div>
        </Button>
    </div>

    const [detailsActive, setDetailsActive] = useState<boolean>(false);

    const handleContentSwitch = (selected?: string | null) => {
        if (!selected) {
            setSelected('');
            setDetailsActive(false);
        } else {
            // @ts-ignore
            setSelected(selected)
            setDetailsActive(true);
        }
    }

    const content =
        selected === 'chrome' ? <Chrome promptFunction={handleInstallClick} closeCallback={() => handleContentSwitch()} /> :
        selected === 'samsung' ? <Samsung promptFunction={handleInstallClick} closeCallback={() => handleContentSwitch()} /> :
        selected === 'opera' ? <Opera promptFunction={handleInstallClick} closeCallback={() => handleContentSwitch()} /> :
        selected === 'safari' ? <Safari promptFunction={handleInstallClick} closeCallback={() => handleContentSwitch()} /> :
        selected === 'other' ? <Other promptFunction={handleInstallClick} closeCallback={() => handleContentSwitch()} /> :
        initialContent;

    return <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
        <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <span>App installeren</span> <Button color={'inherit'} onClick={onClose}><CloseIcon/></Button>
        </DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions>
            {detailsActive ?
                <Button onClick={() => handleContentSwitch()}>Terug</Button> : null}
        </DialogActions>
    </Dialog>
}

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Zoom} from "@mui/material";
import {forwardRef, ReactElement} from "react";

interface Props {
    scanResult: string;
    onClose: () => void;
    onSelectScanResult: (scanResult: string) => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});


export default function ScanResultDialog({scanResult, onClose, onSelectScanResult}: Props) {

    return <Dialog open={!!scanResult} onClose={onClose} fullWidth TransitionComponent={Transition}>
        <DialogTitle>
            <h3 style={{textAlign: 'center'}}>Bevestig printer</h3>
        </DialogTitle>
        <DialogContent dividers sx={{textAlign: 'center'}}>
            <p>klopt het dat u de volgende printer gescand hebt?</p>
            <h2>{scanResult}</h2>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button onClick={onClose} variant={'outlined'} style={{width: '100px'}}>Nee</Button>
            <Button onClick={() => onSelectScanResult(scanResult)}
                    style={{color: '#000', width: '100px', background: '#73e261'}} variant={'contained'}>Ja</Button>
        </DialogActions>
    </Dialog>

}
import {constants} from "../xhr/constants";
import Match from "../interface/match.interface";


export const imageToText = async (canvas: HTMLCanvasElement): Promise<any> => {
    const base64 = canvas.toDataURL("image/jpeg");
    console.log(base64)

    const response = await fetch(`${constants.API_URL}/ocr/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
            "image": base64.split('base64,')[1]
        })
    })
    const json = await response.json()
    return json.result;
}


export const findMatch = async (text: string): Promise<Match[]> => {
    const response = await fetch(`${constants.API_URL}/match/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
            "text": text
        })
    })

    const matches = await response.json()
    if (matches)
        return matches;
    return [];

}
//
// export const findMatch = (text: string): string => {
//
//     if (text.length < 3) return '';
//
//     // check contains 100% match
//     for (let printername in DUMMY_PRINTERS) {
//         if (text.toLowerCase().includes(printername)) {
//             return printername;
//         }
//     }
//
//     let match = stringSimilarity.findBestMatch(text.toLowerCase(), DUMMY_PRINTERS)
//
//     if (match.bestMatch.rating > 0.8) {
//         return match.bestMatch.target
//     }
//
//     return 'corsair';
// }